import React, {useCallback, useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import styled, {css} from "styled-components";

// Contexts
import {NavContext} from "../../contexts/nav.js";
import {useSocket} from "../../contexts/socket.js";

// Hooks
import useApi from "../../hooks/useApi.js";

// Utils
import {toTitleCase} from "../../utils/helpers.js";

// Components
import Modal from "../../components/Modal.js";
import InputText from "../../components/form/InputText.js";
import InputColor from "../../components/form/InputColor.js";
import InputTextArea from "../../components/form/InputTextArea.js";
import InputCheck from "../../components/form/InputCheck.js";
import {ACTIVE_STATES, GET_FACILITY_IDS} from "../general/Room.js";

// Style
import {border, colors, pad} from "../../style/components/variables.js";
import {
  Button,
  Form,
  FormField,
  Heading,
  Inline,
  Text,
  Warning
} from "../../style/components/general.js";

const ModalFacilityStates = ({visible, setVisible}) => {
  const socket = useSocket();

  const {states, getStates} = useContext(NavContext);

  const [targetState, setTargetState] = useState();
  const [stateOn, setStateOn] = useState(false);
  const [hasNotifications, setHasNotifications] = useState(false);

  const defaultValues = {
    name: "",
    color: colors.heroBlack.replace("#", ""),
    description: "When state is active a badge will show on the Facilities' card."
  };

  const schema = yup.object().shape({
    name: yup.string().required("Name is required"),
    color: yup.string().required("Color is required"),
    description: yup.string().required("Description is required"),
    hasFrequencyChanges: yup.boolean()
  });

  const form = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  });
  const {handleSubmit, reset} = form;

  const {api: apiFacilityState} = useApi("facility-states");
  const {api: apiNotificationTime} = useApi("notification-times", {suppress: {error: true}});

  const listenActiveFacilityStates = useCallback(({ids}) => {
    if (ids?.length > 0) setStateOn(true);
  }, []);

  useEffect(() => {
    socket.on(ACTIVE_STATES, listenActiveFacilityStates);

    return () => {
      socket.off(ACTIVE_STATES, listenActiveFacilityStates);
    };
  }, [socket, listenActiveFacilityStates]);

  const targetChange = key => {
    socket.emit(GET_FACILITY_IDS, states[key].id);
    reset({name: key, ...states[key]});
    setTargetState({name: key, ...states[key]});
    setStateOn(false);

    apiNotificationTime
      .callGet(null, {notificationName: `State${toTitleCase(key)}`})
      .then(({status, data}) => {
        if (status === 200 && data) setHasNotifications(data?.length > 0);
      });
  };

  const saveState = async ({name, color, description, hasFrequencyChanges}) => {
    const payload = {name, color, description, settings: {hasFrequencyChanges}};

    const success = targetState?.id ? 200 : 201;
    const {status, data} =
      targetState?.id > 0
        ? await apiFacilityState.callPut(targetState.id, payload)
        : await apiFacilityState.callPost(payload);
    if (status === success && data?.state) {
      getStates();
      setVisible(false);
    }
  };

  const handleDelete = () =>
    targetState?.id &&
    apiFacilityState.callDelete(targetState.id).then(({status}) => {
      if (status === 200) {
        getStates();
        setVisible(false);
      }
    });

  return (
    <Modal visible={visible} setVisible={setVisible}>
      <Heading>Manage States</Heading>
      <StateButtons>
        <Target
          type="button"
          onClick={() => {
            setTargetState(undefined);
            reset(defaultValues);
            setHasNotifications(false);
          }}
          inverted={targetState !== undefined}>
          <FontAwesomeIcon icon={faPlus} /> New
        </Target>
        {Object.keys(states)?.map(key => (
          <Target
            type="button"
            key={states[key].id}
            onClick={() => targetChange(key)}
            inverted={targetState?.id !== states[key].id}
            color={`#${states[key].color}`}>
            {key.toUpperCase()}
          </Target>
        ))}
      </StateButtons>
      {(stateOn || hasNotifications) && (
        <Warnings>
          <Warning>Warning!</Warning>
          {stateOn && (
            <Text>
              Selected state is currently active. Please turn the state off if you would like to
              make changes.
            </Text>
          )}
          {hasNotifications && (
            <Text>
              Selected state has active notifications. Please turn the remove notifications if you
              would like to make changes.
            </Text>
          )}
        </Warnings>
      )}
      <FormWrapper disabled={stateOn || hasNotifications}>
        <FormProvider {...form}>
          <Form onSubmit={handleSubmit(saveState)} noValidate>
            <FormField>
              <InputColor name="color" label="Banner Color" requried />
            </FormField>
            <FormField>
              <InputText name="name" label="Name" placeholder="State..." required />
            </FormField>
            <FormField>
              <InputTextArea
                name="description"
                label="Description"
                placeholder="When this state is active..."
                required
              />
            </FormField>
            <FormField>
              <InputCheck name="hasFrequencyChanges">
                Does this state require changes to checksheet frequencies?
              </InputCheck>
            </FormField>
            <Inline>
              <Button type="submit">{targetState?.id ? "Update" : "Add"}</Button>
              {targetState?.id && (
                <Delete type="button" onClick={handleDelete}>
                  Delete
                </Delete>
              )}
            </Inline>
          </Form>
        </FormProvider>
      </FormWrapper>
    </Modal>
  );
};

ModalFacilityStates.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired
};

// Style Overrides
const StateButtons = styled(Inline)`
  flex-wrap: wrap;
`;

const Target = styled(Button)`
  background: ${({color, theme}) => color || theme.secondary};

  border: ${border} solid ${({color, theme}) => color || theme.secondary};

  ${({inverted}) =>
    inverted &&
    css`
      background: transparent;
      color: ${({color, theme}) => color || theme.secondary};

      svg {
        fill: ${({color, theme}) => color || theme.secondary};
      }
    `}
`;

const Warnings = styled.div`
  margin-top: ${pad}px;
`;

const FormWrapper = styled.div`
  opacity: ${({disabled}) => (disabled ? 0.8 : 1)};
  pointer-events: ${({disabled}) => (disabled ? "none" : "inherit")};
`;

const Delete = styled(Button)`
  background: ${({theme}) => theme.error};
`;

export default ModalFacilityStates;
