import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter, faMapMarkerAlt, faPlus} from "@fortawesome/free-solid-svg-icons";

// Utils
import useMountedState from "../../hooks/useMountedState.js";
import useApi from "../../hooks/useApi.js";
import usePrevious from "../../hooks/usePrevious.js";
import {useSocket} from "../../contexts/socket.js";
import {SettingsContext} from "../../contexts/settings.js";
import {getWithExpiry, prettyDateInUserTimezone, setWithExpiry} from "../../utils/helpers.js";
import {AuthContext} from "../../contexts/auth.js";
import {DEFAULT_MARKER} from "../../utils/google/maps.js";

// Components
import BaseTable from "../../components/BaseTable.js";
import Pagination from "../../components/Pagination.js";
import Dropdown from "../../components/Dropdown.js";
import Badge from "../../components/Badge.js";

// Style
import {voice} from "../../style/components/typography.js";
import {border, colors, pad, radius} from "../../style/components/variables.js";
import {Button, Heading, Inline, Pill, TableFooter, Text} from "../../style/components/general.js";

// Socket Constants
import {RELOAD_EVENT} from "../general/Room.js";

const TableEvent = ({
  facility,
  viewEvent,
  addEvent,
  filters,
  setFilters,
  showArchived,
  setShowModalFilter,
  markerIconMap
}) => {
  const isMounted = useMountedState();
  const socket = useSocket();

  const {settings} = useContext(SettingsContext);

  const {roleCanAccessResource} = useContext(AuthContext);

  const {api, loading} = useApi("events");

  const [events, setEvents] = useState(null);
  // Pagination
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(0);
  const [pageTotal, setPageTotal] = useState(1);
  const [groupBy, setGroupBy] = useState(getWithExpiry("tableEventGroupBy") || "name");
  const [orderBy, setOrderBy] = useState(getWithExpiry("tableEventOrderBy") || "asc");
  const [limit, setLimit] = useState(10);
  const [limits, setLimits] = useState([]);
  // Previous Hooks
  const prevOrderBy = usePrevious(orderBy);
  const prevGroupBy = usePrevious(groupBy);
  const prevLimit = usePrevious(limit);

  const headings = facility?.id
    ? {
        event: {header: "Event", disabled: true},
        name: {header: "Name", disabled: false},
        type: {header: "Type", disabled: false},
        frequency: {header: "Frequency", disabled: false},
        group: {header: "Location", disabled: false},
        dateDue: {header: "Next Due", disabled: true},
        lastCompleted: {header: "Last Completed", disabled: true}
      }
    : {
        event: {header: "Event", disabled: true},
        facility: {header: "Facility", disabled: false},
        name: {header: "Name", disabled: false},
        type: {header: "Type", disabled: false},
        frequency: {header: "Frequency", disabled: false},
        group: {header: "Location", disabled: false},
        dateDue: {header: "Next Due", disabled: true},
        lastCompleted: {header: "Last Completed", disabled: true}
      };

  useEffect(() => {
    setWithExpiry("tableEventGroupBy", groupBy);
    setWithExpiry("tableEventOrderBy", orderBy);
  }, [groupBy, orderBy]);

  const getFilterCount = useCallback(f => {
    let count = 0;
    if (f)
      Object.keys(f).map(filter => {
        count += f[filter]?.length || 0;
      });
    return count;
  }, []);

  const filterCount = useMemo(() => getFilterCount(filters), [getFilterCount, filters]);

  const getEvents = useCallback(
    page => {
      const params = {
        page: page || current,
        orderBy: orderBy,
        groupBy: groupBy,
        limit: limit,
        showArchived: showArchived || false
      };
      setWithExpiry("scheduleShowArchived", showArchived);

      if (facility?.id) params["facilityId"] = facility.id;

      if (filterCount > 0) params["filter"] = JSON.stringify(filters);

      api.callGet(null, params).then(({status, data}) => {
        if (status === 200) {
          const {events: eventsData, total: eTotal, page: ePage, pages} = data;

          const formatted = eventsData.map(event =>
            !facility?.id
              ? {
                  event: (
                    <Button
                      type="button"
                      onClick={() => viewEvent(event)}
                      data-testid="tableRecord.preview">
                      View
                    </Button>
                  ),
                  facility: `${event?.facility.name} ${event?.facility.type}`,
                  name: event.name,
                  type: (
                    <Pill color={`#${event.type.color}`} quiet>
                      {event.type.name}
                    </Pill>
                  ),
                  frequency: event?.frequency ? <Pill quiet>{event.frequency.name}</Pill> : "",
                  group:
                    Object.keys(markerIconMap)?.length > 0 &&
                    event.group &&
                    event?.facility.builder.byId[event.group] ? (
                      <>
                        <Icon
                          icon={faMapMarkerAlt}
                          color={`#${
                            markerIconMap[event.facility.builder.byId[event.group]?.markerId]
                              ?.color || DEFAULT_MARKER.color
                          }`}
                        />
                        <span>{event.facility.builder.byId[event.group].label}</span>
                      </>
                    ) : (
                      ""
                    ),
                  dateDue: prettyDateInUserTimezone(
                    event.dateDue,
                    settings.timezone,
                    "ddd, MMM D YYYY"
                  ),
                  lastCompleted: event.lastCompleted
                    ? prettyDateInUserTimezone(
                        event.lastCompleted,
                        settings.timezone,
                        "ddd, MMM D YYYY"
                      )
                    : null,
                  isDeleted: event.isArchived
                }
              : {
                  event: (
                    <Button type="button" onClick={() => viewEvent(event)}>
                      View
                    </Button>
                  ),
                  name: event.name,
                  type: (
                    <Pill color={`#${event.type.color}`} quiet>
                      {event.type.name}
                    </Pill>
                  ),
                  frequency: event?.frequency ? <Pill quiet>{event.frequency.name}</Pill> : "",
                  group:
                    event.group && event.facility.builder.byId[event.group] ? (
                      <>
                        <Icon
                          icon={faMapMarkerAlt}
                          color={`#${
                            markerIconMap[event.facility.builder.byId[event.group].markerId]
                              ?.color || DEFAULT_MARKER.color
                          }`}
                        />
                        <span>{event.facility.builder.byId[event.group].label}</span>
                      </>
                    ) : (
                      ""
                    ),
                  dateDue: prettyDateInUserTimezone(
                    event.dateDue,
                    settings.timezone,
                    "ddd, MMM D YYYY"
                  ),
                  lastCompleted: event.lastCompleted
                    ? prettyDateInUserTimezone(
                        event.lastCompleted.completedAt,
                        settings.timezone,
                        "ddd, MMM D YYYY"
                      )
                    : null,
                  isDeleted: event.isArchived
                }
          );

          // Page Limit
          let count = 10;
          const temp = [];
          while (count < eTotal + 10 && count <= 50) {
            temp.push(count);
            count += 10;
          }

          setEvents(formatted);
          setTotal(eTotal);
          setCurrent(ePage);
          setPageTotal(pages);
          setLimits(temp);
        }
      });
    },
    [
      api,
      current,
      facility,
      filters,
      filterCount,
      groupBy,
      limit,
      markerIconMap,
      orderBy,
      settings,
      viewEvent,
      showArchived
    ]
  );

  // Initial Load
  useEffect(() => {
    if (isMounted() && events === null) getEvents();
  }, [isMounted, events, getEvents]);

  // Socket Management
  const reloadEvents = useCallback(() => getEvents(1), [getEvents]);

  useEffect(() => {
    if (isMounted()) socket.on(RELOAD_EVENT, reloadEvents);

    return () => {
      // unbind all event handlers used in this component
      socket.off(RELOAD_EVENT, reloadEvents);
    };
  }, [socket, isMounted, reloadEvents]);

  // Load filters from storage
  useEffect(() => {
    const stored = getWithExpiry("scheduleFilters");
    if (isMounted() && stored) setFilters(JSON.parse(stored));
  }, [isMounted, setFilters]);

  // Update rows on filter change
  useEffect(() => {
    if (isMounted() && filters) getEvents();
  }, [isMounted, getFilterCount, filters, getEvents]);

  // Update rows on sort or limit
  useEffect(() => {
    if (
      isMounted() &&
      prevOrderBy &&
      prevGroupBy &&
      prevLimit &&
      (prevOrderBy !== orderBy || prevGroupBy !== groupBy || prevLimit !== limit)
    )
      getEvents();
  }, [isMounted, prevOrderBy, prevGroupBy, prevLimit, orderBy, groupBy, limit, getEvents]);

  return (
    <TableWrapper>
      <TableWrap>
        <Heading>All Events</Heading>
        <Inline>
          <Button type="button" onClick={() => setShowModalFilter(true)}>
            <Badge count={filterCount} offset="14px" color={colors.heroGreen} />
            <FontAwesomeIcon icon={faFilter} />
          </Button>
          {roleCanAccessResource("event", "create") && addEvent && (
            <Button data-testid="calendar.addEvent" onClick={() => addEvent()}>
              <FontAwesomeIcon icon={faPlus} alt="Add Event" />
            </Button>
          )}
        </Inline>
      </TableWrap>
      <BaseTable
        loading={loading}
        headings={headings}
        data={events}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        groupBy={groupBy}
        setGroupBy={setGroupBy}
      />
      <TableFooter>
        <Inline>
          {total > 10 && limits.length > 0 && (
            <PageLimit>
              <Dropdown
                options={limits}
                selection={limit}
                setSelection={selection => {
                  setLimit(selection);
                  setCurrent(1);
                }}
              />
            </PageLimit>
          )}
          <Text quiet>
            {pageTotal > 1 && limits.length > 0 && "per page, "} {total} total
          </Text>
        </Inline>
        {pageTotal > 1 && (
          <Pagination
            current={current}
            setCurrent={setCurrent}
            pageTotal={pageTotal}
            updateData={getEvents}
            loading={loading}
          />
        )}
      </TableFooter>
    </TableWrapper>
  );
};

TableEvent.propTypes = {
  facility: PropTypes.objectOf(PropTypes.any),
  viewEvent: PropTypes.func.isRequired,
  addEvent: PropTypes.func,
  filters: PropTypes.objectOf(PropTypes.any),
  setFilters: PropTypes.func.isRequired,
  showArchived: PropTypes.bool,
  setShowModalFilter: PropTypes.func.isRequired,
  markerIconMap: PropTypes.objectOf(PropTypes.any).isRequired
};

TableEvent.defaultProps = {
  facility: null,
  filters: null,
  addEvent: null,
  showArchived: false
};

// Style Overrides
const TableWrapper = styled.section`
  border: ${border} solid ${({theme}) => theme.secondary};
  border-radius: ${radius};
  max-width: 100%;
`;

const TableWrap = styled.nav`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${pad}px;
  gap: ${pad}px;

  ${Heading} {
    ${voice.medium};
  }
`;

const PageLimit = styled.div`
  margin-right: ${pad}px;

  select {
    padding: ${pad / 2}px;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  ${voice.quiet};
  margin-right: ${pad / 2}px;
  font-weight: bold;
  fill: ${({color, theme}) => color || theme.tertiary};
`;

export default TableEvent;
