import React, {useContext, useMemo} from "react";
import {useNavigate} from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";

// Components
import Badge from "../../components/Badge";
import Select from "../../components/Select";

// Contexts
import {NotificationContext} from "../../contexts/notify";

// Utils
import {slugify} from "../../utils/helpers";

// Style
import {heroTheme, pad} from "../../style/components/variables";
import {Abbr, Pill, Title} from "../../style/components/general";

const FacilityPageHeader = ({facility, path, reloadData, disabled}) => {
  const {name, type, types, isDeleted} = facility;

  const {notifications} = useContext(NotificationContext);

  const navigate = useNavigate();

  const getCount = id => {
    if (!path?.includes("tasks") || !notifications?.otherTypes) return "";

    const count = notifications.otherTypes[`${id}`]?.totalDueCount;

    if (!count) return "";

    return ` (${count})`;
  };

  const totalCount = useMemo(() => {
    if (!path?.includes("tasks") || !notifications?.otherTypes) return null;

    let count = 0;

    Object.keys(notifications.otherTypes).map(id => {
      count += notifications.otherTypes[`${id}`]?.totalDueCount || 0;
    });

    return count;
  }, [notifications, path]);

  return (
    <PageTitle>
      {name.toUpperCase()}
      <Wrapper>
        <BadgeWrapper>
          <Select
            large
            options={types.map(({name: typeName, id}) => ({
              label: `${typeName}${getCount(id)}`,
              name: typeName
            }))}
            selection={type}
            setSelection={target => {
              navigate(`/facilities/${slugify(`${name} ${target.name}`)}${path || ""}`);
              if (reloadData) reloadData();
            }}
            disabled={disabled}
          />
          {!!totalCount && (
            <Badge offsetX="-10px" offsetY="5px" color={heroTheme.secondary}>
              {totalCount}
            </Badge>
          )}
        </BadgeWrapper>
      </Wrapper>
      <Wrapper>
        {isDeleted && (
          <Pill color={heroTheme.warning}>
            <Abbr title="Archived">ARCHIVED</Abbr>
          </Pill>
        )}
      </Wrapper>
    </PageTitle>
  );
};

FacilityPageHeader.propTypes = {
  facility: PropTypes.objectOf(PropTypes.any).isRequired,
  path: PropTypes.string,
  reloadData: PropTypes.func,
  disabled: PropTypes.bool
};

FacilityPageHeader.defaultProps = {
  path: null,
  reloadData: null,
  disabled: false
};

// Style Overrides
const PageTitle = styled(Title)`
  display: inline-block;
  width: 100%;
`;

const Wrapper = styled.span`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-left: ${pad}px;
`;

const BadgeWrapper = styled.div`
  display: flex;
`;

export default FacilityPageHeader;
