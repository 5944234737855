import React, {useContext, useMemo} from "react";
import PropTypes from "prop-types";

// Hooks
import useApi from "../../hooks/useApi.js";

// Contexts
import {AuthContext} from "../../contexts/auth.js";

// Components
import Modal from "../../components/Modal.js";

// Style
import {Text, Button, Form, FormGroup, Inline} from "../../style/components/general.js";

const ModalConfirmFavoriteFacility = ({visible, setVisible, facility, reloadFacilities}) => {
  const {currentUser, getCurrentUser} = useContext(AuthContext);

  const {api} = useApi("facility-users");

  const favorites = useMemo(() => currentUser?.favorites || [], [currentUser]);

  const includesFacility = useMemo(() => !!favorites?.includes(facility.id), [favorites, facility]);

  const handleSubmit = e => {
    e.preventDefault();
    api.callPut(null, {facility: facility.id, favorite: !includesFacility}).then(({status}) => {
      if (status === 200) {
        getCurrentUser();
        reloadFacilities();
        setVisible(false);
      }
    });
  };

  return (
    <Modal visible={visible} setVisible={setVisible}>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Text>
            {!includesFacility
              ? `Favoriting ${facility.name} will pin it to the top of the dashboard.`
              : "Are you sure you want to unfavorite this facility?"}
          </Text>
        </FormGroup>
        <Inline>
          <Button type="button" onClick={() => setVisible(false)}>
            Cancel
          </Button>
          <Button type="submit">Confirm</Button>
        </Inline>
      </Form>
    </Modal>
  );
};

ModalConfirmFavoriteFacility.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  facility: PropTypes.objectOf(PropTypes.any).isRequired,
  reloadFacilities: PropTypes.func.isRequired
};

export default ModalConfirmFavoriteFacility;
