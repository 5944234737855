import React, {useContext, useState, useEffect, useCallback, useRef, useMemo} from "react";
import PropTypes from "prop-types";
import {useParams} from "react-router";
import styled from "styled-components";

// Hooks
import useMountedState from "../../hooks/useMountedState.js";
import useApi from "../../hooks/useApi.js";

// Contexts
import {useSocket} from "../../contexts/socket.js";
import {AuthContext} from "../../contexts/auth.js";
import {NotificationContext} from "../../contexts/notify.js";
import {SettingsContext} from "../../contexts/settings.js";

// Utils
import {prettyDateInUserTimezone} from "../../utils/helpers.js";

// Components
import ChecksheetDue from "./ChecksheetDue.js";
import Accordion from "../../components/Accordion.js";

// Style
import {voice} from "../../style/components/typography.js";
import {pad} from "../../style/components/variables.js";
import {flex} from "../../style/components/mixins.js";
import {Bullet, HeadingMedium} from "../../style/components/general.js";

// Socket Constants
import {
  REQUEST_LOCKS,
  LIST_LOCKS,
  getFacilityRooms,
  LISTEN_NOTIFY_FREQUENCY_CHANGE,
  LISTEN_NOTIFY_CHECKSHEET_OPTIONAL_CHANGE
} from "../general/Room.js";

const ChecksheetsDue = ({room, canRequestLocks, setHasUpdates, refreshingNotifications}) => {
  const isMounted = useMountedState();

  const socket = useSocket();

  const {slug} = useParams();

  const {settings} = useContext(SettingsContext);

  const {currentUser, roleCanAccessResource} = useContext(AuthContext);

  const {notifications} = useContext(NotificationContext);

  const [locks, setLocks] = useState(null);

  const [hide, setHide] = useState([]);
  const [optionalOpen, setOptionalOpen] = useState(false);

  const {api: apiChecksheets} = useApi("checksheets");

  const requestingLocks = useRef(false);

  useEffect(() => {
    if (refreshingNotifications.current && notifications) {
      refreshingNotifications.current = false;
      setHide([]);
    }
  }, [notifications, refreshingNotifications]);

  const listLocks = useCallback(
    ({lockList, userId, fromPercentComplete, type}) => {
      // if type is null, this event should be accepted by both Events and Checksheets
      // if userId is null, then it was another event action that triggered listLocks
      // if userId is defined, it is either from request_locks or percent_complete and should be ignored if:
      //        userId is current user, and request was not from percent complete
      //        userId is not the current user, and request was from percent complete
      if (
        (!type || type === "checksheet") &&
        (!userId ||
          (userId === currentUser.publicId && !fromPercentComplete) ||
          (userId !== currentUser.publicId && fromPercentComplete))
      )
        setLocks(lockList);
    },
    [currentUser]
  );

  // Socket Management
  useEffect(() => {
    if (isMounted() && slug && currentUser) {
      if (!locks && canRequestLocks && !requestingLocks.current) {
        requestingLocks.current = true;
        socket.emit(REQUEST_LOCKS, {
          rooms: getFacilityRooms(slug, "checksheet"),
          user: currentUser.publicId,
          type: "checksheet"
        });
      }
    }

    socket.on(LIST_LOCKS, listLocks);

    return () => {
      // unbind all event handlers used in this component
      socket.off(LIST_LOCKS, listLocks);
    };
  }, [isMounted, socket, slug, currentUser, listLocks, locks, canRequestLocks]);

  const notifyFrequencyChange = useCallback(
    checksheetId => {
      if (
        !optionalOpen &&
        notifications.checksheetsOptional.map(({id}) => `${id}`).includes(checksheetId)
      ) {
        apiChecksheets.callGet(checksheetId).then(({status, data}) => {
          if (status === 200 && data?.frequency) setHide(prev => [...prev, `${data.id}`]);
        });
      }
    },
    [apiChecksheets, setHide, notifications, optionalOpen]
  );

  const notifyChecksheetOptionalChange = useCallback(() => setHasUpdates(true), [setHasUpdates]);

  // Socket Management
  useEffect(() => {
    socket.on(LISTEN_NOTIFY_FREQUENCY_CHANGE, notifyFrequencyChange);
    socket.on(LISTEN_NOTIFY_CHECKSHEET_OPTIONAL_CHANGE, notifyChecksheetOptionalChange);

    return () => {
      // unbind all event handlers used in this component
      socket.off(LISTEN_NOTIFY_FREQUENCY_CHANGE, notifyFrequencyChange);
      socket.off(LISTEN_NOTIFY_CHECKSHEET_OPTIONAL_CHANGE, notifyChecksheetOptionalChange);
    };
  }, [socket, notifyFrequencyChange, notifyChecksheetOptionalChange]);

  const checksheetsDueCount = useMemo(
    () =>
      notifications?.checksheetsDue?.filter(checksheet => !hide.includes(`${checksheet.id}`))
        ?.length || 0,
    [notifications, hide]
  );

  return (
    <Checksheets>
      {locks && notifications?.checksheetsDue && (
        <>
          <Glance>
            {checksheetsDueCount > 0 && (
              <>
                <HeadingMedium>
                  {checksheetsDueCount} Checksheet
                  {checksheetsDueCount > 1 ? "s" : ""} Upcoming
                </HeadingMedium>

                {notifications.checksheetsDue
                  .filter(checksheet => !hide.includes(`${checksheet.id}`))
                  .map(checksheet => (
                    <ChecksheetDue
                      key={`${checksheet.slug}-${checksheet.id}`}
                      room={room}
                      locks={locks}
                      checksheet={checksheet}
                      setHide={setHide}
                      setHasUpdates={setHasUpdates}
                    />
                  ))}
              </>
            )}

            {(notifications.totalDueCount === 0 || checksheetsDueCount === 0) && (
              <MessageWrapper>
                <HeadingMedium>Checksheets</HeadingMedium>
                <Bullet>
                  {notifications.hasChecksheets
                    ? "You are all caught up!"
                    : "Create a checksheet or event to get started!"}
                </Bullet>
              </MessageWrapper>
            )}
          </Glance>

          {notifications.hasExternalChecksheets &&
            notifications?.checksheetsExternal?.length > 0 && (
              <Glance>
                <GlanceHeading>
                  {notifications.checksheetsExternal.length} External Checksheet
                  {notifications.checksheetsExternal.length > 1 ? "s" : ""} Upcoming
                </GlanceHeading>
                {notifications.checksheetsExternal.map(checksheet => (
                  <Bullet key={checksheet.id}>
                    {checksheet.name} is available and due{" "}
                    {prettyDateInUserTimezone(checksheet.dateDue, settings.timezone)}, but only
                    available via email.
                  </Bullet>
                ))}
              </Glance>
            )}

          {roleCanAccessResource("facility_checksheet_record", "create") &&
            notifications?.checksheetsOptional?.filter(({id}) => !hide.includes(`${id}`))?.length >
              0 && (
              <Accordion
                label="Optional Checksheets"
                handleOpen={() => setOptionalOpen(prev => !prev)}
                toggleOpen={optionalOpen}>
                <Glance>
                  {notifications.checksheetsOptional
                    .filter(checksheet => !hide.includes(`${checksheet.id}`))
                    .map(checksheet => (
                      <ChecksheetDue
                        key={`${checksheet.slug}-${checksheet.id}`}
                        room={room}
                        locks={locks}
                        checksheet={checksheet}
                        setHide={setHide}
                        setHasUpdates={setHasUpdates}
                      />
                    ))}
                </Glance>
              </Accordion>
            )}
        </>
      )}
    </Checksheets>
  );
};

ChecksheetsDue.propTypes = {
  room: PropTypes.string.isRequired,
  canRequestLocks: PropTypes.bool.isRequired,
  setHasUpdates: PropTypes.func.isRequired,
  refreshingNotifications: PropTypes.objectOf(PropTypes.bool).isRequired
};

// Style Overrides
const Checksheets = styled.div`
  position: relative;
`;

const MessageWrapper = styled.div`
  padding-top: ${pad * 3}px;
`;

const Glance = styled.div`
  ${flex("column", "nowrap")};
  gap: ${pad}px;
  margin-bottom: ${pad}px;
  width: 100%;
`;

const GlanceHeading = styled.h2`
  ${voice.strong};
  font-weight: bold;
  padding: ${pad}px 0;
  color: ${({theme}) => theme.secondary};
`;

export default ChecksheetsDue;
