import React, {useContext} from "react";
import {useForm, FormProvider} from "react-hook-form";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";

// Contexts
import {NavContext} from "../../contexts/nav.js";

// Utils
import {getWithExpiry, setWithExpiry} from "../../utils/helpers.js";

// Components
import Modal from "../../components/Modal.js";
import InputCheckGroup from "../../components/form/InputCheckGroup.js";

// Style
import {
  HeadingCenter,
  Button,
  Form,
  ButtonLoader,
  FormField,
  Inline
} from "../../style/components/general.js";

const TYPES = [
  "WATER",
  "WASTEWATER",
  "LIFT STATION",
  "COLLECTION SYSTEM",
  "INDUSTRIAL WASTE",
  "STORMWATER"
];

const CHECKSHEET_STATUSES = ["DUE", "INCOMPLETE", "OVERDUE"];

const ModalFacilityFilter = ({
  visible,
  setVisible,
  filters,
  setFilters,
  showArchived,
  setShowArchived,
  hasBackButton,
  goBack
}) => {
  const loading = false;

  const {states: allStates} = useContext(NavContext);

  const form = useForm({
    defaultValues: {...filters, archived: showArchived}
  });
  const {setValue, watch, handleSubmit, reset} = form;
  const watchArchived = watch("archived");

  const applyFilters = ({types, checksheets, states, archived}) => {
    const updated = {};

    if (types?.length > 0) updated.types = types;
    if (checksheets?.length > 0) updated.checksheets = checksheets;
    if (states?.length > 0) updated.states = states;

    if (archived !== showArchived) {
      setWithExpiry("facilitiesDashboardFiltersArchived", archived);
      setShowArchived(archived);
    }

    setFilters(updated);
    setWithExpiry("facilitiesDashboardFilters", updated);
    setVisible(false);
  };

  const handleClear = () => {
    localStorage.removeItem("facilitiesDashboardFilters");
    localStorage.removeItem("facilitiesDashboardFiltersArchived");
    setFilters(undefined);
    setShowArchived(false);
    reset();
  };

  return (
    <Modal visible={visible} setVisible={setVisible} hasBackButton={hasBackButton} goBack={goBack}>
      <HeadingCenter>Facility Filters</HeadingCenter>
      <Form onSubmit={handleSubmit(applyFilters)}>
        <FormProvider {...form}>
          <FormField>
            <InputCheckGroup
              name="types"
              label="Facility Type"
              options={TYPES}
              defaultSelection={getWithExpiry("facilitiesDashboardFilters")}
            />
          </FormField>

          <FormField>
            <InputCheckGroup
              name="checksheets"
              label="Checksheet Status"
              options={CHECKSHEET_STATUSES}
              defaultSelection={getWithExpiry("facilitiesDashboardFilters")}
            />
          </FormField>

          {allStates && Object.keys(allStates)?.length > 0 && (
            <FormField>
              <InputCheckGroup
                name="states"
                label="Facility States"
                options={Object.keys(allStates).map(key => ({
                  value: allStates[key].id,
                  label: key.toUpperCase()
                }))}
                defaultSelection={getWithExpiry("facilitiesDashboardFilters")}
              />
            </FormField>
          )}

          <Inline>
            <Button type="submit" loading={loading ? 1 : 0}>
              {loading && <ButtonLoader />}
              Apply
            </Button>
            <Button type="button" onClick={handleClear}>
              Clear
            </Button>
            <Button
              type="submit"
              title={watchArchived ? "Hide Archived" : "Show Archived"}
              onClick={() => setValue("archived", !watchArchived)}>
              <FontAwesomeIcon icon={watchArchived ? faEyeSlash : faEye} />
              &nbsp;Archived
            </Button>
          </Inline>
        </FormProvider>
      </Form>
    </Modal>
  );
};

ModalFacilityFilter.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  filters: PropTypes.objectOf(PropTypes.any),
  setFilters: PropTypes.func.isRequired,
  showArchived: PropTypes.bool.isRequired,
  setShowArchived: PropTypes.func.isRequired,
  hasBackButton: PropTypes.bool,
  goBack: PropTypes.func
};

ModalFacilityFilter.defaultProps = {
  filters: null,
  hasBackButton: false,
  goBack: null
};

export default ModalFacilityFilter;
