import React, {useContext, useState, useEffect, useCallback, useRef, useMemo} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Contexts
import {AuthContext} from "../../contexts/auth.js";

// Hooks
import useMountedState from "../../hooks/useMountedState.js";
import useApi from "../../hooks/useApi.js";

// Components
import FacilityCard from "./FacilityCard.js";

// Style
import {pad} from "../../style/components/variables.js";
import {bp} from "../../style/components/breakpoints.js";
import {flex} from "../../style/components/mixins.js";
import {Loader, TextCenter} from "../../style/components/general.js";

const FacilityCards = ({
  facilities,
  setFacilities,
  query,
  filters,
  orderBy,
  showArchived,
  setTargetFacility,
  setShowSettingsModal,
  setShowConfirmFavoriteModal
}) => {
  const isMounted = useMountedState();

  const {currentUser, roleCanAccessResource} = useContext(AuthContext);

  const [current, setCurrent] = useState(1);
  const [pages, setPages] = useState(1);
  const [slugs, setSlugs] = useState(null);

  const favorites = useMemo(() => currentUser?.favorites || [], [currentUser]);

  const limit = useRef(12);

  const {api, loading} = useApi("facilities");

  const getFacilities = useCallback(
    (p = 1, l = null, withSlugs = false) => {
      const filter = {};
      const payload = {
        page: p,
        limit: l || limit.current,
        orderBy,
        groupBy: "name", // constant for cards
        withTasksDue: true,
        withAllTypes: !showArchived,
        withDeleted: showArchived,
        withSlugs,
        favorites: favorites || []
      };

      if (query) filter.Search = query;
      if (filters?.types) filter.Type = filters.types;
      if (filters?.checksheets) filter.Checksheet = filters.checksheets;
      if (filters?.states) filter.State = filters.states;

      if (filter && Object.keys(filter).length > 0) payload.filter = JSON.stringify(filter);

      api.callGet(null, payload).then(({status, data}) => {
        if (isMounted() && status === 200) {
          const {page: cPage, pages: cPages, facilities: visible, slugs: allSlugs} = data;
          setCurrent(cPage);
          setPages(cPages);
          setFacilities(prev => (prev ? [...prev, ...visible] : visible));
          if (allSlugs) setSlugs(allSlugs);
        }
      });
    },
    [isMounted, api, orderBy, query, filters, setFacilities, showArchived, favorites]
  );

  // Initial Load
  useEffect(() => {
    if (isMounted() && !facilities) {
      // estimation for number of cards to overflow
      const approxCards = Math.floor(window.innerHeight / 150) * 4;
      limit.current = approxCards;
      getFacilities(1, approxCards, true);
    }
  }, [isMounted, facilities, getFacilities]);

  // Load more on Scroll
  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + Math.ceil(window.scrollY) >= document.documentElement.offsetHeight &&
        current < pages &&
        !loading
      )
        getFacilities(current + 1);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [current, pages, getFacilities, loading]);

  // Update facilities on query, sort and deleted
  useEffect(() => {
    if (isMounted()) setFacilities(null);
  }, [isMounted, query, orderBy, showArchived, setFacilities]);

  const getFacilityMessage = () => {
    if (query || (!!filters && Object.values(filters).some(val => val?.length)))
      return <TextCenter>No facilities found</TextCenter>;
    if (roleCanAccessResource("facility", "create"))
      return (
        <TextCenter>
          No Facilities have been created please click `Create New` to add one.
        </TextCenter>
      );
    return (
      <TextCenter>
        You are not assigned to any facilities, please contact your manager to assign you.
      </TextCenter>
    );
  };

  return (
    <Facilities>
      {!loading && facilities?.length === 0 && getFacilityMessage()}
      {facilities?.map(facility => (
        <CardWrapper key={facility.id}>
          <FacilityCard
            facility={facility}
            slugs={slugs}
            showArchived={showArchived}
            setTarget={setTargetFacility}
            setShowSettingsModal={setShowSettingsModal}
            setShowConfirmFavoriteModal={setShowConfirmFavoriteModal}
            favorites={favorites}
          />
        </CardWrapper>
      ))}

      <LoadWrapper show={loading}>
        <Loader />
      </LoadWrapper>
    </Facilities>
  );
};

FacilityCards.propTypes = {
  facilities: PropTypes.arrayOf(PropTypes.any),
  setFacilities: PropTypes.func.isRequired,
  query: PropTypes.string,
  filters: PropTypes.objectOf(PropTypes.any),
  orderBy: PropTypes.string,
  showArchived: PropTypes.bool,
  setTargetFacility: PropTypes.func.isRequired,
  setShowSettingsModal: PropTypes.func.isRequired,
  setShowConfirmFavoriteModal: PropTypes.func.isRequired
};

FacilityCards.defaultProps = {
  facilities: null,
  query: null,
  filters: null,
  orderBy: "asc",
  showArchived: false
};

// Style Overrides
const Facilities = styled.section`
  position: relative;
  width: 100%;
  min-height: 100px;
  margin: ${pad * 2}px 0;
  gap: ${pad * 1.5}px;
  ${flex("row", "wrap")};
`;

const CardWrapper = styled.div`
  position: relative;
  width: calc(50% - 7.5px);

  ${bp(2)} {
    max-width: calc(33.33333% - 10px);
  }

  ${bp(5)} {
    max-width: calc(25% - 11.25px);
  }
`;

const LoadWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 20px;
  opacity: ${({show}) => (show ? 1 : 0)};

  div {
    margin-top: 0;
  }
`;

export default FacilityCards;
