import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

// Utils
import {exists} from "../../utils/helpers.js";
import {defaultUnacceptableParameterPrompt} from "../../utils/builder.js";
import usePrevious from "../../hooks/usePrevious.js";

// Components
import Modal from "../../components/Modal.js";
import InputTextArea from "../../components/form/InputTextArea.js";

// Style
import {bp} from "../../style/components/breakpoints.js";
import {voice} from "../../style/components/typography.js";
import {pad, status} from "../../style/components/variables.js";
import {
  Button,
  Form,
  Heading,
  HeadingMedium,
  Inline,
  Label,
  Text
} from "../../style/components/general.js";

const buildAlertWarning = (name, fullName, value, condition, unacceptableIcon) => (
  <Info key={`${name}-warning`}>
    <Label bold>{fullName}</Label>
    {unacceptableIcon}
    <RegularText>
      Entered Value: <RedText>{value}</RedText>
    </RegularText>
    {Array.isArray(condition) && (
      <RegularText>Unacceptable Values: {condition.join(", ")}</RegularText>
    )}
  </Info>
);

const buildNumericWarning = (
  name,
  fullName,
  value,
  units,
  min,
  max,
  qualifier,
  unacceptableIcon
) => (
  <Info key={`${name}-warning`}>
    <Label bold>{fullName}</Label>
    {unacceptableIcon}
    <RegularText>
      Entered value:&nbsp;
      <RedText>
        {qualifier && qualifier !== "ND" && qualifier !== "DNQ" && qualifier !== "EST"
          ? `${qualifier} `
          : ""}
        {qualifier && qualifier === "ND" ? "< " : ""}
        {value} {units}
        {(qualifier && qualifier === "ND") || qualifier === "DNQ" || qualifier === "EST"
          ? ` (${qualifier})`
          : ""}
      </RedText>
    </RegularText>
    {exists(min) && exists(max) && (
      <RegularText>
        Acceptable Range: {min} - {max} {units}
      </RegularText>
    )}
    {exists(min) && !exists(max) && (
      <RegularText>
        Minimum Value: {min} {units}
      </RegularText>
    )}
    {!exists(min) && exists(max) && (
      <RegularText>
        Maximum Value: {max} {units}
      </RegularText>
    )}
  </Info>
);

const checkMap = {
  "===": "is",
  "!==": "is not",
  ">": "is greater than",
  "<": "is less than",
  ">=": "is greater than or equal to",
  "<=": "is less than or equal to",
  contains: "has"
};

const compareMap = {
  0: "0",
  1: "1",
  checked: "all checked",
  unchecked: "all unchecked",
  one: "one checked",
  more: "at least one checked",
  some: "at least one unchecked",
  true: "True",
  false: "False"
};

const ModalRangeAlert = ({
  visible,
  setVisible,
  outsideRange,
  outsideRangeMultiple,
  notifyFieldCondition,
  confirmFunction,
  reviseFunction,
  values,
  isSubmitting
}) => {
  const [rangeWarnings, setRangeWarnings] = useState(null);
  const [schema, setSchema] = useState(null);

  const form = useForm({resolver: yupResolver(schema)});
  const {
    handleSubmit,
    reset,
    watch,
    formState: {isDirty}
  } = form;

  const typing = useRef(null);

  const watchInputs = watch();
  const prevInputs = usePrevious(watchInputs);

  const buildNotifyWarning = useMemo(
    () =>
      Object.keys(notifyFieldCondition).map(name => {
        const {fullName, check, compare, subject} = notifyFieldCondition[name];
        return (
          <Info key={`${name}-warning`}>
            <Label bold>
              {fullName}&nbsp;{checkMap[check]}&nbsp;
              {compare && compare.includes("Other: ")
                ? compare.replace("Other: ", "")
                : compareMap[compare]}
            </Label>
            <RegularText>
              {subject ? `${subject} n` : "N"}otification will be sent on submission.
            </RegularText>
          </Info>
        );
      }),
    [notifyFieldCondition]
  );

  const buildRangeWarning = useCallback((obj, name, unacceptableIcon) => {
    const {fullName, qualifier, entered, units, max, min, alert, value, condition} = obj[name];

    if (alert) return buildAlertWarning(name, fullName, value, condition, unacceptableIcon);

    return buildNumericWarning(
      name,
      fullName,
      entered,
      units,
      min,
      max,
      qualifier,
      unacceptableIcon
    );
  }, []);

  const generateContent = useCallback(() => {
    const partialValidationSchema = {};
    const partialDefaultValues = {};

    const result = [];

    Object.keys(outsideRangeMultiple).map((key, i, arr) => {
      const {name: notificationTitle, prompt, dependencyInfo} = outsideRangeMultiple[key];

      const savedValue = values[`${key}_comment`] || "";

      const formValue = watch(`${key}_comment`);

      if (!schema) {
        partialDefaultValues[`${key}_comment`] = savedValue;
        partialValidationSchema[`${key}_comment`] = yup
          .string()
          .nullable()
          .required("Please provide an explanation for the unacceptable parameter");
      }

      result.push(
        <UnacceptableWrapper key={key}>
          {notificationTitle && <CustomHeading>{notificationTitle}</CustomHeading>}
          {((schema && !formValue) || (!schema && !savedValue)) && (
            <StyledInline>
              <UnacceptableIcon icon={faExclamationCircle} fill={status.error} />
              &nbsp;Requires explanation
            </StyledInline>
          )}
          {Object.keys(dependencyInfo).map(n => buildRangeWarning(dependencyInfo, n))}
          <InputTextArea
            name={`${key}_comment`}
            label={prompt || defaultUnacceptableParameterPrompt}
            required
            maxLength={1000}
          />
          {(i < arr.length - 1 || (outsideRange && Object.keys(outsideRange).length > 0)) && (
            <>
              <br />
              <hr />
            </>
          )}
        </UnacceptableWrapper>
      );
    });

    Object.keys(outsideRange)
      .filter(key => outsideRange[key])
      .map((key, i, arr) => {
        const savedValue = values[`${key}_comment`] || "";
        const formValue = watch(`${key}_comment`);

        if (!schema) {
          partialDefaultValues[`${key}_comment`] = savedValue;
          partialValidationSchema[`${key}_comment`] = yup
            .string()
            .nullable()
            .required("Please provide an explanation for the unacceptable parameter");
        }

        const unacceptableIcon =
          (schema && !formValue) || (!schema && !savedValue) ? (
            <StyledInline>
              <UnacceptableIcon icon={faExclamationCircle} fill={status.error} hasPadding />
              &nbsp;Requires explanation
            </StyledInline>
          ) : null;

        result.push(
          <UnacceptableWrapper key={key}>
            {buildRangeWarning(outsideRange, key, unacceptableIcon)}
            <InputTextArea
              name={`${key}_comment`}
              label={outsideRange[key]?.prompt || defaultUnacceptableParameterPrompt}
              required
              maxLength={1000}
            />
            {(i < arr.length - 1 ||
              (notifyFieldCondition && Object.keys(notifyFieldCondition).length > 0)) && (
              <>
                <br />
                <hr />
              </>
            )}
          </UnacceptableWrapper>
        );
      });

    if (!schema) {
      setSchema(yup.object().shape(partialValidationSchema));
      reset(partialDefaultValues);
    }
    setRangeWarnings(result);
  }, [
    buildRangeWarning,
    notifyFieldCondition,
    outsideRange,
    outsideRangeMultiple,
    reset,
    schema,
    values,
    watch
  ]);

  useEffect(() => {
    generateContent();
  }, [generateContent]);

  const handleInputRateLimit = useCallback(() => {
    if (typing.current) clearTimeout(typing.current);
    const timer = setTimeout(() => {
      generateContent();
    }, 500);
    typing.current = timer;
  }, [generateContent]);

  // Store current draft in redis and update percentage
  useEffect(() => {
    if (isDirty && Object.entries(watchInputs).some(([key, val]) => prevInputs[key] !== val))
      handleInputRateLimit();
  }, [watchInputs, handleInputRateLimit, isDirty, prevInputs]);

  return (
    <Modal visible={visible} setVisible={setVisible}>
      <ModalHeading error>Warning</ModalHeading>
      <Text>The following alerts were triggered based on entered values:</Text>
      <FormProvider {...form}>
        <Form onSubmit={handleSubmit(confirmFunction)}>
          {(outsideRange || outsideRangeMultiple) && rangeWarnings}
          {notifyFieldCondition && buildNotifyWarning && buildNotifyWarning.length > 0 && (
            <>
              <CustomHeading>Notifications</CustomHeading>
              {buildNotifyWarning}
            </>
          )}
          <ButtonBar>
            <Button type="submit">Acknowledge &amp; Submit</Button>
            {isSubmitting && (
              <Button type="button" onClick={reviseFunction}>
                Revise
              </Button>
            )}
          </ButtonBar>
        </Form>
      </FormProvider>
    </Modal>
  );
};

ModalRangeAlert.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  outsideRange: PropTypes.objectOf(PropTypes.any),
  outsideRangeMultiple: PropTypes.objectOf(PropTypes.any),
  notifyFieldCondition: PropTypes.objectOf(PropTypes.any),
  confirmFunction: PropTypes.func.isRequired,
  reviseFunction: PropTypes.func.isRequired,
  values: PropTypes.objectOf(PropTypes.any),
  hasNextStep: PropTypes.bool,
  isSubmitting: PropTypes.bool
};

ModalRangeAlert.defaultProps = {
  hasNextStep: false,
  isSubmitting: false,
  values: {},
  outsideRange: {},
  outsideRangeMultiple: {},
  notifyFieldCondition: {}
};

// Style Overrides
const ModalHeading = styled(Heading)`
  ${({error, theme}) =>
    error &&
    css`
      color: ${theme.error};
    `}
`;

const CustomHeading = styled(HeadingMedium)`
  margin-top: ${pad}px;
`;

const UnacceptableWrapper = styled.div`
  width: 100%;
`;

const Info = styled.div`
  margin-top: ${pad}px;
`;

const ButtonBar = styled(Inline)`
  display: flex;
  flex-direction: column-reverse;
  gap: ${pad}px;
  margin-top: ${pad * 2}px;

  button {
    width: 100% !important;
    max-width: 100% !important;
  }

  ${bp(3)} {
    flex-direction: row-reverse;

    button {
      width: max-content !important;
      max-width: max-content !important;
    }
  }
`;

const RedText = styled.span`
  color: ${({theme}) => theme.error};
  text-align: left;
  ${voice.normal};
`;

const RegularText = styled.div`
  text-align: left;
  color: ${({theme}) => theme.secondary};
  ${voice.normal};
`;

const UnacceptableIcon = styled(FontAwesomeIcon)`
  fill: ${({fill}) => fill || "black"};
  vertical-align: 1em;

  ${({hasPadding}) =>
    hasPadding &&
    css`
      padding: ${pad / 2}px 0;
    `}
`;

const StyledInline = styled(Inline)`
  ${voice.quiet}
`;

export default ModalRangeAlert;
