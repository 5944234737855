import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {Navigate} from "react-router-dom";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faPlus,
  faSearch,
  faSortAlphaDown,
  faSortAlphaUp
} from "@fortawesome/free-solid-svg-icons";

// Contexts
import {SettingsContext} from "../contexts/settings.js";
import {AuthContext} from "../contexts/auth.js";
import {NotificationContext} from "../contexts/notify.js";

// Hooks
import useMountedState from "../hooks/useMountedState.js";
import useApi from "../hooks/useApi.js";

// Utils
import {getWithExpiry} from "../utils/helpers.js";

// Components
import FacilityCards from "./facilities/FacilityCards.js";
import TableFacilities from "./facilities/TableFacilities.js";
import FacilityMap from "./facilities/FacilityMap.js";
import ModalFacilityAdd from "./facilities/ModalFacilityAdd.js";
import ModalFacilityFilter from "./facilities/ModalFacilityFilters.js";
import ModalFacilitySettings from "./facilities/ModalFacilitySettings.js";
import Badge from "../components/Badge.js";
import ToggleMenu from "./facilities/ToggleMenu.js";

// Style
import {pad, colors} from "../style/components/variables.js";
import {flex, z} from "../style/components/mixins.js";
import {bp} from "../style/components/breakpoints.js";
import {Page, Title, Button, Search, SearchIcon, Abbr} from "../style/components/general.js";
import ModalConfirmFavoriteFacility from "./facilities/ModalConfirmFavoriteFacility.js";

const FacilitiesDashboard = () => {
  const isMounted = useMountedState();

  const {settings} = useContext(SettingsContext);
  const {currentUser, atMost, roleCanAccessPage, roleCanAccessResource} = useContext(AuthContext);
  const {getNotifications} = useContext(NotificationContext);

  const {api: apiFacilityTypes} = useApi("facility-types");

  const [facilities, setFacilities] = useState(null);
  const [targetFacility, setTargetFacility] = useState(null);
  const [view, setView] = useState(localStorage.getItem("facilitiesDashboardView") || "card");
  const [currentTablePage, setCurrentTablePage] = useState(1);
  // Filters
  const [query, setQuery] = useState(null);
  const [orderBy, setOrderBy] = useState("asc");
  const [filters, setFilters] = useState();
  const [showArchived, setShowArchived] = useState(false);
  // Modals
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [showConfirmFavoriteModal, setShowConfirmFavoriteModal] = useState(false);

  // Initial Load
  useEffect(() => {
    if (isMounted()) {
      getNotifications();
      apiFacilityTypes.callGet();

      setFilters(getWithExpiry("facilitiesDashboardFilters"));
      setShowArchived(getWithExpiry("facilitiesDashboardFiltersArchived"));
    }
  }, [isMounted, getNotifications, apiFacilityTypes]);

  const typing = useRef(false);

  const handleInputRateLimit = input => {
    if (typing.current) clearTimeout(typing.current);
    const timer = setTimeout(() => {
      setQuery(input);
      typing.current = null;
    }, 200);
    typing.current = timer;
  };

  // Reset on view change
  useEffect(() => {
    setCurrentTablePage(1);
  }, [view]);

  const filterCount = useMemo(() => {
    let count = 0;
    if (filters)
      Object.keys(filters).map(filter => {
        count += filters[filter]?.length || 0;
      });

    return count + (showArchived ? 1 : 0);
  }, [filters, showArchived]);

  if (facilities?.length === 1 && atMost("admin") && roleCanAccessPage("facility_dashboard"))
    return <Navigate to={`/facilities/${facilities[0].slug}`} />;

  return (
    <Page>
      <HeaderWrap>
        {settings?.siteTitle && <Title>{settings.siteTitle.toUpperCase()}</Title>}
        <Options>
          {roleCanAccessResource("facility", "create") && (
            <Option>
              <Button type="button" onClick={() => setShowModalAdd(true)}>
                <Abbr title="Add New Facility">
                  <FontAwesomeIcon icon={faPlus} />
                </Abbr>
              </Button>
            </Option>
          )}
          <Option>
            <SearchIcon>
              <FontAwesomeIcon icon={faSearch} />
            </SearchIcon>
            <Search
              name="search"
              type="text"
              placeholder="Search"
              onChange={({target}) => handleInputRateLimit(target.value)}
            />
          </Option>
          {view !== "map" && (
            <Option>
              <Button
                type="button"
                onClick={() => {
                  if (orderBy === "asc") setOrderBy("desc");
                  if (orderBy === "desc") setOrderBy("asc");
                }}>
                <Abbr title={orderBy === "desc" ? "Sort Z to A" : "Sort A to Z"}>
                  <FontAwesomeIcon icon={orderBy === "desc" ? faSortAlphaDown : faSortAlphaUp} />
                </Abbr>
              </Button>
            </Option>
          )}
          <Option>
            <Button type="button" onClick={() => setShowModalFilter(prev => !prev)}>
              <Badge count={filterCount} offset="14px" color={colors.heroGreen} />
              <Abbr title="Filter">
                <FontAwesomeIcon icon={faFilter} />
              </Abbr>
            </Button>
          </Option>
          <Option>
            <ToggleMenu view={view} setView={setView} setFacilities={setFacilities} />
          </Option>
        </Options>
      </HeaderWrap>

      {view === "card" && (
        <FacilityCards
          facilities={facilities}
          setFacilities={setFacilities}
          query={query}
          filters={filters}
          orderBy={orderBy}
          showArchived={showArchived}
          setTargetFacility={setTargetFacility}
          setShowSettingsModal={setShowSettingsModal}
          setShowConfirmFavoriteModal={setShowConfirmFavoriteModal}
        />
      )}

      {view === "table" && (
        <TableFacilities
          facilities={facilities}
          setFacilities={setFacilities}
          query={query}
          filters={filters}
          orderBy={orderBy}
          showArchived={showArchived}
          currentPage={currentTablePage}
          setCurrentPage={setCurrentTablePage}
          setTarget={setTargetFacility}
          setShowSettingsModal={setShowSettingsModal}
        />
      )}

      {view === "map" && <FacilityMap query={query} filters={filters} />}

      {showModalAdd && (
        <ModalFacilityAdd
          visible={showModalAdd}
          setVisible={setShowModalAdd}
          reloadFacilities={() => {
            // trigger reload
            setQuery(null);
            setFacilities(null);
          }}
        />
      )}

      {showModalFilter && (
        <ModalFacilityFilter
          visible={showModalFilter}
          setVisible={setShowModalFilter}
          filters={filters}
          setFilters={f => {
            setFilters(f);
            setShowModalFilter(false);
            // trigger reload
            setFacilities(null);
          }}
          showArchived={showArchived}
          setShowArchived={setShowArchived}
        />
      )}

      {showSettingsModal && (
        <ModalFacilitySettings
          visible={showSettingsModal}
          setVisible={setShowSettingsModal}
          user={currentUser}
          facility={targetFacility}
          reloadFacilities={() => {
            // trigger reload
            setQuery(null);
            setFacilities(null);
          }}
        />
      )}

      {showConfirmFavoriteModal && (
        <ModalConfirmFavoriteFacility
          visible={showConfirmFavoriteModal}
          setVisible={setShowConfirmFavoriteModal}
          facility={targetFacility}
          reloadFacilities={() => {
            // trigger reload
            setQuery(null);
            setFacilities(null);
          }}
        />
      )}
    </Page>
  );
};

// Style Overrides
const HeaderWrap = styled.nav`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: ${pad * 2}px 0;

  ${bp(2)} {
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding: ${pad}px 0;
  }
`;

const Options = styled.div`
  margin-top: ${pad}px;
  z-index: ${z("top") + 1};
  ${flex("row", "nowrap", "center", "center")};

  ${bp(2)} {
    margin: 0;
  }
`;

const Option = styled.div`
  position: relative;
  height: 100%;
  margin-right: ${pad / 2}px;
  ${flex("row", "nowrap", "start", "center")};

  ${bp(2)} {
    margin-right: ${pad}px;
  }

  &:last-child {
    margin-right: 0;
  }
`;

export default FacilitiesDashboard;
